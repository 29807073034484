<template>
    <section>
      <!-- slide -->
        <div class="row content-wrapper pt-0">
					<div class="col grid-margin">
            <b-carousel id="carousel2" controls indicators background="#ababab" :interval="3000" v-model="slide2">
                <b-carousel-slide>
                  <picture slot="img">
                    <source srcset="https://sites.comolapapaya.com/img/carousel-1-60e2e2d3f1e9b.webp"  type="image/webp">
                    <source srcset="https://sites.comolapapaya.com/img/carousel-1-60e2e2d3f1e9b.png" type="image/png">
                    <img src="https://sites.comolapapaya.com/img/carousel-1-60e2e2d3f1e9b.png" alt="il n'y pas d'image, Importer une image"/>
                  </picture>
                  <!-- <img slot="img" src="../assets/images/import/carousel-2.png" alt="slide image" loading=lazy> -->
                  <h3 style="color:#fff; text-align:center">Hello world!</h3>
                  <p style="color:#fff; text-align:center">Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                  <button type="button" class="btn btn btn-gradient-dark btn-fw btn-secondary">Découvrir</button>
                </b-carousel-slide>
                <b-carousel-slide>
                  <picture slot="img">
                    <source srcset="https://sites.comolapapaya.com/img/carousel-2-60f192b77f1df.webp"  type="image/webp">
                    <source srcset="https://sites.comolapapaya.com/img/carousel-2-60f192b77f1df.png" type="image/png">
                    <img src="https://sites.comolapapaya.com/img/carousel-2-60f192b77f1df.png" alt="il n'y pas d'image, Importer une image"/>
                  </picture>
                  <h3 style="color:#311312; text-align:right">Hello world!</h3>
                  <p style="color:rgb(50, 24, 22); text-align:right">Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                  <button type="button" class="btn btn btn-gradient-dark btn-fw btn-secondary">Découvrir</button>
                </b-carousel-slide>
                <b-carousel-slide>
                  <picture slot="img">
                    <source srcset="https://sites.comolapapaya.com/img/carousel-3-60f192d430971.webp"  type="image/webp">
                    <source srcset="https://sites.comolapapaya.com/img/carousel-3-60f192d430971.png" type="image/png">
                    <img src="https://sites.comolapapaya.com/img/carousel-3-60f192d430971.png" alt="il n'y pas d'image, Importer une image"/>
                  </picture>
                  <h3 style="color:#FFF; text-align:left">Hello world!</h3>
                  <p style="color:#FFF; text-align:left">Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                  <button type="button" class="btn btn btn-gradient-dark btn-fw btn-secondary">Découvrir</button>
                </b-carousel-slide>
                <b-carousel-slide>
                  <picture slot="img">
                    <source srcset="https://sites.comolapapaya.com/img/carousel-4-60f192ea8f116.webp"  type="image/webp">
                    <source srcset="https://sites.comolapapaya.com/img/carousel-4-60f192ea8f116.png" type="image/png">
                    <img src="https://sites.comolapapaya.com/img/carousel-4-60f192ea8f116.png" alt="il n'y pas d'image, Importer une image"/>
                  </picture>
                  <h3 style="color:#fff; text-align:center">Hello world!</h3>
                  <p style="color:#fff; text-align:center">Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                  <button type="button" class="btn btn btn-gradient-dark btn-fw btn-secondary">Découvrir</button>
                </b-carousel-slide>
              </b-carousel>
					</div>
        </div>

        <div class="content-wrapper pt-0">
          <card-pagination title="Catégories" id="category">
            <card-image v-for="(category, categoryId) in categories"
            :key="categoryId"
            :title="category.category"
            @showDetails= showCategoryDetails(category)
            >
            </card-image>
          </card-pagination>
          <!-- <sliding-figure :items="categories" headerTitle="Nos produits">
          </sliding-figure> -->
          
          <!-- nouveaux produits (slide) -->
          <sliding-card id="newProducts" :items="newProducts" title="Les Nouveaux produits" data-aos="fade-right" data-aos-duration="1800"/>

          <!-- produits populaires (slide) -->
          <sliding-card id="popularProducts" :items="popularProduct" title="Les produits les plus vendus" data-aos="fade-left" data-aos-duration="1800"/>
        </div> <!-- fin content wrapper-->

        <banner />

        <div class="content-wrapper pt-0">
          <!-- Actualités -->
          <div class="row mt-5 mb-5">
            <div class="col-md-8 mx-auto py-5 text-center">
              <h1 >Actualités</h1>
              <h4 class="font-weight-light"> This template comes with AngularJs and HTML5/jQuery version, which helps to choose the best dashboard solution to fit your project needs. </h4>
            </div>
          </div>
          <div class="card-columns">
            <div class="card">
              <img class="card-img-top" src="../assets/images/import/actu-2.jpg" alt="Card image cap">
              <div class="card-body">
                <h4 class="card-title">Du miel artisanal</h4>
                <p class="card-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad blanditiis quam, sequi dolorum excepturi repudiandae atque dignissimos voluptatum aperiam!</p>
              </div>
            </div>
            <div class="card">
              <img class="card-img-top" src="../assets/images/import/actu-1.jpg" alt="Card image cap">
              <div class="card-body">
                <h4 class="card-title">Du miel Bio</h4>
                <p class="card-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quisquam deleniti</p>
              </div>
            </div>
            <div class="card">
              <img class="card-img-top" src="../assets/images/import/actu-3.jpg" alt="Card image cap">
              <div class="card-body">
                <h4 class="card-title">L'histoire de Beekeeper974</h4>
                <p class="card-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Explicabo omnis, nesciunt neque quisquam deserunt, fugiat quasi sint recusandae, ipsa architecto quos adipisci facilis necessitatibus sequi repellat mollitia, minus maxime porro. Asperiores
                  necessitatibus pariatur, sunt accusamus cupiditate. Voluptates.</p>
              </div>
            </div>
            <div class="card">
              <img class="card-img-top" src="../assets/images/import/actu-4.jpg" alt="Card image cap">
              <div class="card-body">
                <h4 class="card-title">Titre</h4>
                <p class="card-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum hic officia animi quod iure, vel aliquam quia recusandae accusantium quo impedit labore, eligendi ipsam, fugit tempora, explicabo deleniti minus iste! </p>
              </div>
            </div>
            <div class="card">
              <img class="card-img-top" src="../assets/images/import/actu-5.jpg" alt="Card image cap">
              <div class="card-body">
                <h4 class="card-title">Titre</h4>
                <p class="card-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro eaque similique veniam consequatur ipsum.</p>
              </div>
            </div>
            <div class="card">
              <img class="card-img-top" src="../assets/images/import/actu-6.jpg" alt="Card image cap">
              <div class="card-body">
                <h4 class="card-title">Titre</h4>
                <p class="card-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laudantium nostrum et libero quasi obcaecati laborum cumque quos hic at commodi soluta, dolorum ea, repellat nulla fugit.</p>
              </div>
            </div>
          </div>

          <!-- livraison -->
          <div class="details-wrapper" style="background:none" >
            <div class="container">
              <div class="row">
                <div class="col-sm-4 pb-3" data-aos="fade-up" data-aos-duration="1500">
                  <div class="card card-icon-top">
                    <div class="card-body text-center">
                      <img src="../assets/images/import/leaf.png" alt="image" class="card-icon">
                      <h4>Produits Sains</h4>
                      <p class="card-text"> Bio, suivant les normes internationales </p>
                    </div>
                  </div>
                </div>
                <div class="col-sm-4 pb-3" data-aos="fade-up" data-aos-duration="2000">
                  <div class="card card-icon-top">
                    <div class="card-body text-center">
                      <img src="../assets/images/import/delivery-truck.png" alt="image" class="card-icon">
                      <h4>Livraison Rapide</h4>
                      <p class="card-text"> </p>
                    </div>
                  </div>
                </div>
                <div class="col-sm-4 pb-3" data-aos="fade-up" data-aos-duration="3000">
                  <div class="card card-icon-top">
                    <div class="card-body text-center">
                      <img src="../assets/images/import/verified.png" alt="image" class="card-icon">
                      <h4>Sécurisé</h4>
                      <p class="card-text"> Payez en toute tranquilité </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        

        <!-- produits populaires-->
        <!-- <simpleCard :perPage="3" id="popularProduct" :items="newProducts" title="Nouveaux produits" col="col-lg-4 col-md-6 col-sm-6 col-12"/> -->


        <!-- produits populaires -->
        <!-- <simpleCard :perPage="3" id="popularProduct" :items="popularProduct" title="Produits populaires" col="col-lg-4 col-md-6 col-sm-6 col-12"/> -->

        

       
    </section>
</template>

<script>
import Banner from '../components/banner.vue'
// import simpleCard from '../components/pagination/simpleCard.vue'
import SlidingCard from '../components/pagination/slidingCard.vue'
// import SlidingFigure from '../components/pagination/slidingFigure.vue'
import CardPagination from '../components/pagination/card-pagination.vue'
import CardImage from '../components/element/card-image.vue'
const axios= require('axios').default
export default {
  name:'home',
  components: {
    // simpleCard,
    SlidingCard,
    // SlidingFigure,
    Banner,
    CardPagination,
    CardImage
  },
  data () {
    return {
      slide1: 0,
      slide2: 0,
      slide3: 0,
      currentPage:1,
      currentPageNewproduct:1,
      categories: [],
      pictures:[],
      perPage3:3,
      // newProducts:[
      //   {name: 'miel 1', price: 20, weight: 20, volume: 200, stock:10, shippinglocation:'39**' ,date:"2021-03-18 16:53:01"},
      //   {name: 'miel 2', price: 40, weight: 20, volume: 200, stock:10, shippinglocation:'39**' ,date:"2021-03-20 16:53:01"},
      //   {name: 'miel 3', price: 40, weight: 20, volume: 200, stock:10, shippinglocation:'39**' ,date:"2021-03-18 15:53:01"},
      //   {name: 'miel 4', price: 40, weight: 20, volume: 200, stock:10, shippinglocation:'39**' ,date:"2021-03-21 16:53:01"},
      //   {name: 'miel 5', price: 40, weight: 20, volume: 200, stock:10, shippinglocation:'39**' ,date:"2021-03-22 16:53:01"},
      //   {name: 'miel 6', price: 40, weight: 20, volume: 200, stock:10, shippinglocation:'39**' ,date:"2021-03-23 16:53:01"}
      // ], 
      popularProduct:[
        {name: 'Confiture 1', price: 40, weight: 20, volume: 200, stock:10, shippinglocation:'39**'},
        {name: 'Confiture 2', price: 40, weight: 20, volume: 200, stock:10, shippinglocation:'39**'},
        {name: 'Confiture 3', price: 40, weight: 20, volume: 200, stock:10, shippinglocation:'39**'},
        {name: 'Confiture 4', price: 40, weight: 20, volume: 200, stock:10, shippinglocation:'39**'},
        {name: 'Confiture 4', price: 40, weight: 20, volume: 200, stock:10, shippinglocation:'39**'},
        {name: 'Confiture 4', price: 40, weight: 20, volume: 200, stock:10, shippinglocation:'39**'},
        {name: 'Confiture 4', price: 40, weight: 20, volume: 200, stock:10, shippinglocation:'39**'},
        {name: 'Confiture 4', price: 40, weight: 20, volume: 200, stock:10, shippinglocation:'39**'},
        {name: 'Confiture 4', price: 40, weight: 20, volume: 200, stock:10, shippinglocation:'39**'},
        {name: 'Confiture 4', price: 40, weight: 20, volume: 200, stock:10, shippinglocation:'39**'},
      ]
    }
  },
  computed: {
    newProducts () {
      return this.$store.state.allProducts.slice(-5).sort (( a, b ) => new Date (b.date) - new Date (a.date))
    }
  },
  methods: {
    getCategories() {
      axios.get('categories')
      .then(resCategory=> {
        this.categories= resCategory.data.result
        console.log('this.categories', this.categories);
      })
      .catch(errCategory=> console.log({errCategory}))
    },
    showCategoryDetails(category) {
            this.$router.push({
                name:'category',
                params: { 
                   ulid: category.ulid,
                   categoryName:category.category
                 }
            })
    }
  },
  mounted(){
    this.getCategories()
  }
}
</script>

<style scoped>
</style>