<template>
    <section class="card-pagination">
        <div class="page-header">
            <h3 class="page-title">
            {{title}}
            </h3>
        </div>
        <div class="row" >
            <div class="scrollcards" :id="id">
                <slot>
                </slot>
            </div>
            <div class="col-md-12">
                <a @click="scrollleft()" role="button" aria-controls="carousel2___BV_inner_" class="carousel-control-prev computer"><span aria-hidden="true" class="carousel-control-prev-icon"></span><span class="sr-only">Previous slide</span></a>
                <a @click="scrollright()" role="button" aria-controls="carousel2___BV_inner_" class="carousel-control-next computer"><span aria-hidden="true" class="carousel-control-next-icon"></span><span class="sr-only">Next slide</span></a>
            </div>
            
        </div>
    </section>
</template>

<script>
export default {
    name:'card-pagination',
    data () {
        return {
        }
    },
    props: {
        title: String,
        id: String
    },
    methods: {
        scrollright() {
            document.getElementById(`${this.id}`).scrollLeft += 1150;
        },
        scrollleft() {
            document.getElementById(`${this.id}`).scrollLeft -= 1150;
        }
    }
}
</script>
<style scoped>
::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    background: transparent;
} 
.scrollcards {
  /* background-color: #fff; */
  display: block;
  overflow-x: auto;
  white-space: nowrap;
}

.carousel-control-prev-icon {
    background-image: url('../../assets/images/import/arrow-preview.png');
    width: 50px;
    height: 50px;
    margin: -353px 100px 0px -63px
    }
.carousel-control-next-icon {
    background-image: url('../../assets/images/import/arrow-next.png');
    width: 50px;
    height: 50px;
    margin: 0px -69px 353px 79px
    }
</style>