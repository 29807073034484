<template>
    <section class="mt-5">
        <div class="page-header">
            <h3 class="page-title">
            {{title}}
            </h3>
        </div>
        <div class="row product-item-wrapper">
            <div class="scrollcards" :id="id">
                <div class=" col-lg-4 col-md-6 col-sm-6 col-12 px-0 product-item stretch-card">
                    <div class="card mr-2" v-for="(item, i) in items" :key="i" @click="showDetails(item)">
                        <div class="card-body">
                        <div class="action-holder">
                            <div class="sale-badge bg-gradient-dark">New</div>
                            <span class="favorite-button"><i class="mdi mdi-heart-outline"></i></span>
                        </div>
                        <div class="product-img-outer">
                            <img class="product_image" src="@/assets/images/import/3.jpg" alt="prduct image" @click="showDetails(item)" style="cursor:pointer">
                        </div>
                        <p class="product-title" @click="showDetails(item)" style="cursor:pointer">{{item.name.length>nameLength?item.name.substring(0, 20) + "...": item.name}}</p>
                        <p class="product-price">{{item.price}} €</p>
                        </div>
                    </div>
                </div>
            </div>
            <a @click="scrollleft()" role="button" aria-controls="carousel2___BV_inner_" class="carousel-control-prev computer"><span aria-hidden="true" class="carousel-control-prev-icon"></span><span class="sr-only">Previous slide</span></a>
            <a @click="scrollright()" role="button" aria-controls="carousel2___BV_inner_" class="carousel-control-next computer"><span aria-hidden="true" class="carousel-control-next-icon"></span><span class="sr-only">Next slide</span></a>
        </div>
    </section>
</template>

<script>
export default {
    name:'slidingCard',
    data() {
        return{
            nameLength: 20
        }
    },
    props: {
        id: String,
        items: Array,
        title: String,
        col: String,
        name: String,
        price: Number,
    },
    methods: {
        showDetails(product) {
            const ulid= product.ulid
            this.$router.push({
                name:'product',
                params: { ulid }
            })
        },
        scrollright() {
            document.getElementById(`${this.id}`).scrollLeft += 1000;
        },
        scrollleft() {
            document.getElementById(`${this.id}`).scrollLeft -= 1000;
        }
    }
}
</script>

<style lang="scss" scoped>
 .scrollcards {
  /* background-color: #fff; */
  overflow-x: auto;
  white-space: nowrap;
}

::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    background: transparent;
} 

div.scrollcards .card {
    display: inline-block;
    /* padding: 14px; */
    text-decoration: none;
    height: auto; 
    width: 300px;
}


.carousel-control-prev-icon {
    background-image: url('../../assets/images/import/arrow-preview.png');
    width: 50px;
    height: 50px;
    margin: 0px 81px 0px -122px
    }
.carousel-control-next-icon {
    background-image: url('../../assets/images/import/arrow-next.png');
    width: 50px;
    height: 50px;
    margin: 0px -121px 0px 79px
    }
</style>