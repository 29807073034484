<template>
    <section class="card-image" :class="column">
        <div class="card" style="width: 18rem;">
            <img class="card-img-top p-3" :src="randomImages">
            <div class="card-body px-1 pb-3 pt-0">
                <h4 class="text-center" @click="showDetails()">{{title}}</h4>
                <p class="card-text text-center">{{description}}</p>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'card-image',
    data () {
        return {
            images: [
                require('../../assets/images/import/miel-transparent.png'),
                require('../../assets/images/import/miel-transparent-2.png'),
                require('../../assets/images/import/miel-transparent-3.png'),
                require('../../assets/images/import/miel-transparent-4.png'),
                require('../../assets/images/import/miel-transparent-5.png')
                ]
        }
    },
    computed: {
        randomImages() {
            return this.images[Math.floor(Math.random()*this.images.length)]
        }
    },
    props: {
        sectionTitle: String,
        title: String,
        description: String,
        column: String
    },
    methods: {
        showDetails() {
            this.$emit('showDetails')
        }
    }
}
</script>

<style scoped>
.card-image {
    display: inline-block;
    text-decoration: none;
    height: auto; 
    width: 288px;
}

.card{
    width: 17rem !important;
}

</style>