<template>
  <section class="app-footer">
    <footer class="footer px-0">
        <section class="module parallax parallax-1 d-flex">
          <div class="container d-flex align-self-center align-items-center justify-content-center">
              <div class=" banner-text">
                  <div class="d-flex justify-content-center">
                      <h3 class="align-items-center text-center">Retrouvez nos packs promotionnels de 50%</h3>
                  </div>
                  <div class="d-flex justify-content-center">
                      <button class="btn btn-gradient-dark align-items-center">Cliquez ici</button>
                  </div>
              </div>
          </div>
      </section>
    </footer>
  </section>
</template>

<script>
export default {
  name: 'banner'
}
</script>

<style scoped>

.footer a, a:hover {
  color: white;
  text-decoration: none
}
.banner-text {
    background: none;
    padding: 0rem 1rem;
    /* text-align: center;
    align-self: center; */
}
h2, h3{
    color: white;
}

.container {
    max-width: 960px;
    margin: 0 auto;
}

section.module:last-child{
    margin-bottom: 0;
}

section.module.content{
    padding: 40px 0;
}

section.module.parallax {
    height: 200px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
}

section.module.parallax h1{
    color: rgba(255, 255, 255, 0.8);
    font-size: 48px;
    line-height: 600px;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

}
section.module.parallax-1{
    background-image: url('./../assets/images/import/gift.jpg')!important;
    width: 100%
}

@media all and (min-width: 600px) {
    section.module h2 {
        font-size: 42px;
    }
    section.module p {
        font-size: 20px;
    }
    section.module.parallax h1 {
        font-size: 96px;
    }
}
@media all and (min-width: 960px) {
    section.module.parallax h1 {
        font-size: 160px;
    }
}
</style>

<!--<style scoped>
footer {
    min-height: 220px;
  /* background: -webkit-gradient(linear, left top, left bottom, from(rgb(42, 42, 42)), color-stop(8%, rgb(28, 28, 28)), to(rgb(1, 0, 0))) !important; */
  background: url('./../assets/images/import/gift.jpg') center no-repeat!important;
}

.footer a, a:hover {
  color: white;
  text-decoration: none
}
.banner-text {
    background: none;
    padding: 0rem 1rem;
}
h2, h3{
    color: white;
}
</style>-->
